@import '../../../../scss/theme-bootstrap';
@import '../../../../scss/components/_mpp_filters';

// MPP Layout
body {
  .site-content {
    max-width: 100%;
    width: 100%;
    @include breakpoint($large-up) {
      max-width: 100%;
      width: 100%;
    }
  }
  .node-elc-mpp {
    background: $color-sugartone;
  }
}

.breadcrumbs {
  @include swap_direction(padding, 20px 0 10px 20px);
  background: none;
  max-width: $container-max-width;
  @include breakpoint($portrait-up) {
    margin: 0 auto;
  }
  @include breakpoint($landscape-up) {
    @include swap_direction(padding, 20px 0 10px 28px);
  }
  &__level {
    text-decoration: none;
    &::before {
      content: '/';
      font-size: get-rem(10px);
      @include breakpoint($portrait-up) {
        font-size: get-rem(12px);
      }
    }
    &:first-child::before,
    &:last-child::before {
      content: '';
    }
    a {
      font-family: $font--helvetica-neue-regular;
      font-size: get-rem(10px);
      border-bottom: 1px solid transparent;
      color: $color-black;
      letter-spacing: 1px;
      line-height: 0;
      text-decoration: none;
      text-transform: uppercase;
      @include breakpoint($portrait-up) {
        font-size: get-rem(12px);
      }
      &:hover {
        border-bottom: 1px solid $color-black;
      }
    }
  }
}

.sd-product-grid {
  font-family: $primary-font;
  min-height: 500px;
  padding-#{$ldirection}: 20px;
  padding-#{$rdirection}: 20px;
  max-width: $container-max-width;
  @include breakpoint($landscape-up) {
    margin: 0 auto;
    padding-top: 15px;
    padding: 15px 28px 0;
  }
  .elc-product-grid-wrapper {
    margin: 0;
  }
  // Sort.
  .elc-sort-wrapper {
    margin: 0 0 20px;
    .elc-dropdown {
      background: none;
      min-width: 200px;
    }
    .elc-dropdown-wrapper,
    .elc-dropdown-input-wrapper,
    .elc-dropdown-arrow {
      border: none;
      &:hover {
        background: none;
      }
    }
    .elc-dropdown-arrow-icon {
      @include svg-icon-inline-mask('caret--right.svg');
      position: absolute;
      top: 10px;
      #{$rdirection}: 20px;
      width: 15px;
      height: 15px;
      transform: rotate(90deg);
      transition: transform 0.25s;
    }
    .elc-dropdown-arrow[aria-label='close menu'] {
      .elc-dropdown-arrow-icon {
        top: 16px;
        transform: rotate(-90deg);
      }
    }
    .elc-dropdown-options-list {
      border: 1px solid $color-black;
      @include swap_direction(margin, -2px 0 0 -1px);
      width: calc(100% + 2px);
    }
    .elc-dropdown-option:first-child {
      @include breakpoint($portrait-up) {
        display: none;
      }
    }
    .elc-dropdown-readonly-input {
      color: $color-black;
      font-size: get-rem(16px);
      font-family: $font--helvetica-neue-bold;
      justify-content: flex-start;
      padding: 0 12px;
      text-transform: uppercase;
      line-height: 1;
    }
    select,
    .elc-dropdown-option {
      color: $color-black;
      font-size: get-rem(14px);
      font-family: $font--text;
      font-weight: 400;
      line-height: normal;
      padding: 10px 12px;
      text-transform: uppercase;
      @include breakpoint($landscape-up) {
        font-size: get-rem(17px);
      }
    }
    .elc-dropdown-option {
      padding: 10px 20px;
    }
    .elc-dropdown,
    select {
      appearance: none;
      background-color: $color-white;
      border: 1px solid $color-black;
      width: 145px;
      height: 40px;
      border-radius: 0;
    }
    label {
      position: relative;
      &::after {
        @include svg-icon-inline-mask('caret--right.svg');
        background: $color-black;
        content: '';
        position: absolute;
        top: -3px;
        #{$rdirection}: 20px;
        width: 15px;
        height: 15px;
        display: inline-block;
        vertical-align: middle;
        transform: rotate(90deg);
        transition: transform 0.25s;
      }
    }
  }
  // Inline Tout.
  .elc-basic-tout {
    &__link-content,
    &__media-wrapper {
      border: unset;
    }
    &__media {
      position: relative;
      padding: 35px 0;
      @include breakpoint($landscape-up) {
        padding: 45px 0;
      }
    }
    &__eyebrow {
      position: absolute;
      top: 0;
      p {
        margin: 0;
      }
    }
    &__content {
      position: absolute;
      bottom: 16px;
      @include breakpoint($landscape-up) {
        bottom: 0;
      }
      p {
        margin-bottom: 0;
        line-height: 1;
      }
      &-over-media-block {
        padding: 35px 0;
        position: absolute;
        top: 0;
        width: 100%;
        @include breakpoint($landscape-up) {
          padding: 45px 0;
        }
        .elc-basic-tout {
          &__eyebrow,
          &__content {
            position: relative;
          }
        }
      }
      &-over-media-wrapper {
        width: 100%;
      }
    }
  }
  .elc-product-tout-wrapper {
    height: auto;
  }
  .elc-selected-size-space-row {
    margin: 0;
  }
  .elc-grid-item-tout,
  .elc-grid-item-product {
    border-bottom: 1px solid $color-black;
    margin-bottom: 30px;
    position: relative;
    padding: 0 0 30px;
    @include breakpoint($portrait-up) {
      border: none;
      margin-bottom: 70px;
    }
    &:nth-of-type(3n-1),
    &:nth-of-type(3n) {
      @include breakpoint($portrait-up) {
        border-#{$ldirection}: get-rem(2px) solid $color-black;
      }
    }
    &:nth-of-type(3n) {
      @include breakpoint($portrait-up) {
        padding-#{$ldirection}: 10px;
      }
      @include breakpoint($landscape-up) {
        padding-#{$ldirection}: 20px;
      }
      @include breakpoint($xlarge-up) {
        padding-#{$ldirection}: 25px;
      }
    }
    &:nth-of-type(3n-1) {
      @include breakpoint($portrait-up) {
        padding: 0 10px;
      }
      @include breakpoint($landscape-up) {
        padding: 0 20px;
      }
      @include breakpoint($xlarge-up) {
        padding: 0 25px;
      }
    }
    &:nth-of-type(3n-2) {
      @include breakpoint($portrait-up) {
        padding-#{$rdirection}: 25px;
      }
      @include breakpoint($landscape-up) {
        padding-#{$rdirection}: 25px;
      }
      @include breakpoint($xlarge-up) {
        padding-#{$rdirection}: 25px;
      }
    }
  }
  .elc-grid-item-tout {
    border-bottom: get-rem(2px) solid $color-black;
    @include breakpoint($portrait-up) {
      border-bottom: none;
    }
  }
  .elc-product-grid--header {
    justify-content: flex-end;
    padding: 0;
  }
  .elc-sort-wrapper select {
    font-weight: 700;
  }
  .elc-filters-modal-button {
    font-weight: 700;
  }
}

.elc-product-category {
  &-wrapper {
    @include swap_direction(margin, 20px 0 25px 20px);
    display: inline-block;
    @include breakpoint($portrait-up) {
      padding-#{$ldirection}: 25px;
    }
  }
  &-link {
    font-family: $font--helvetica-neue-bold;
    font-size: get-rem(20px);
    border: 1px solid $color-black;
    padding: 15px 25px;
    margin: 20px;
    color: $color-black;
    text-transform: uppercase;
    &:first-child {
      margin: 0;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &-title {
    font-family: $font--helvetica-neue-bold;
    font-size: get-rem(30px);
    margin: 10px 20px;
    display: inline-block;
    text-transform: uppercase;
    @include breakpoint($portrait-up) {
      margin: 10px 25px;
    }
  }
}

// Grid Skeleton.
.sd-grid-skeleton {
  padding: 0;
  .is-slider .skeleton-item {
    border: none;
    padding: 0;
    margin: 0 4px;
  }
  .sd-grid-skeleton-filters {
    padding: 0 0 10px;
    .skeleton-bar {
      max-width: 198px;
    }
  }
  &-items {
    margin-top: 10px;
    .skeleton-item {
      padding: 16px 0 60px;
      .elc-product-brief {
        .elc-product-rating-wrapper {
          display: flex;
        }
        .elc-product-visual-section {
          text-align: center;
          .elc-img--alt {
            display: none;
          }
        }
        .elc-rating {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
        }
        .elc-product-short-description-wrapper {
          letter-spacing: 0.1px;
        }
        .elc-selected-size {
          letter-spacing: 0.25px;
        }
        .elc-selected-size-space-row {
          display: flex;
        }
        .elc-size-picker-box-selected {
          order: 0;
        }
        .elc-size-picker-box {
          order: 1;
        }
      }
    }
  }
}

// LS specific transition updates
.sd-product-grid [data-component='product-grid'].sd-mounted.sd-transition {
  #{$ldirection}: 28px;
  #{$rdirection}: 28px;
}
