.sd-product-grid {
  .elc-orderable-filters-wrapper {
    flex: 1;
  }
}
.sd-product-grid,
.elc-responsive-modal-mask {
  .elc-overlay-wrapper-close-button {
    background: $color-white;
    position: absolute;
    #{$rdirection}: 20px;
    @include breakpoint($portrait-up) {
      display: none;
    }
  }
  .elc-filters-modal-button {
    display: flex;
    background: $color-white;
    border-radius: 0;
    width: auto;
    min-width: 145px;
    height: 40px;
    padding: 11px 20px;
    position: relative;
    font-size: 14px;
    font-family: $font--text;
    // font-weight: 400;
    letter-spacing: normal;
    @include breakpoint($portrait-up) {
      font-family: $font--heading;
      font-size: 16px;
      min-width: 198px;
      padding: 9px 20px;
      font-weight: 700;
    }
    .elc-applied-filters-number {
      display: none;
    }
    .elc-filter-icon {
      @include svg-icon-inline-mask('caret--right.svg');
      position: absolute;
      top: 9px;
      #{$rdirection}: 20px;
      width: 15px;
      height: 15px;
      transform: rotate(90deg);
      transition: transform 0.25s;
    }
    &.active .elc-filter-icon {
      top: 15px;
      transform: rotate(-90deg);
    }
  }
  .elc-filter-set-wrapper {
    margin: 20px 0 0;
    @include breakpoint($portrait-up) {
      margin: 0;
      width: 200px;
    }
    & > label {
      display: block;
      margin-bottom: 10px;
      font-family: $font--heading;
      font-size: get-rem(14px);
      text-transform: uppercase;
      @include breakpoint($portrait-up) {
        font-size: get-rem(16px);
      }
      .elc-styled-filters-active-filters-no {
        display: none;
      }
      &:after {
        content: ':';
        margin-#{$ldirection}: -3px;
      }
    }
    .elc-filter-option {
      display: flex;
      margin-bottom: 0;
      .elc-checkbox {
        display: flex;
        margin-bottom: 14px;
        @include breakpoint($portrait-up) {
          margin-bottom: 12px;
        }
        .elc-checkbox-icon {
          height: 18px;
          width: 18px;
          border: 1px solid $color-primary-500;
          border-radius: 1px;
          background: $color-white;
          -webkit-mask: none;
          mask: none;
        }
        &-selected .elc-checkbox-icon {
          background: $color-max-ls;
        }
        .elc-checkbox-label {
          font-family: $font--text;
          text-transform: uppercase;
          line-height: 18px;
          margin-top: 3px;
          @include breakpoint($portrait-up) {
            margin-top: 6px;
          }
        }
      }
    }
  }
}
// Mobile only modal container
.elc-responsive-modal-mask {
  background: $color-white !important;
  .elc-mobile-filters-modal {
    border-top: 2px solid $color-black;
    top: 0;
    #{$ldirection}: 0;
    transform: none;
    .elc-filters-modal-button {
      padding: 10px 0;
      border: 0;
      font-weight: 400;
      font-family: $font--text;
      .elc-filter-icon {
        top: 14px;
        transform: rotate(-90deg);
      }
    }
  }
}
// Desktop only collapsible container
.elc-product-grid--filters-section {
  position: relative;
  .elc-collapsible-wrapper {
    background: $color-white;
    border: 1px solid $color-black;
    display: flex;
    padding: 20px;
    position: absolute;
    top: 39px;
    z-index: 11;
  }
}
